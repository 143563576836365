import Captcha from '../models/Captcha'
import Contact from '../models/Contact'
import { CustomerPivot, Customers } from '../models/Customers'
import Region from '../models/Region'
import RegionRole from '../models/RegionRole'
import Role from '../models/Role'

export abstract class RegionRoleService {
  static regionRoles: RegionRole[] = []

  /**
   *
   * @param item json object
   * @returns instance of RegionRole
   */
  static fromObject(item: any, roleMap: Map<number, CustomerPivot>) {
    const regionRole = new RegionRole()
    regionRole.id = item.id
    regionRole.createdAt = item.created_at
    regionRole.updatedAt = item.updated_at

    const region = new Region()
    region.id = item.region.id
    region.name = item.region.name
    region.short = item.region.short
    regionRole.region = region

    const role = new Role()
    role.id = item.role.id
    role.name = item.role.name
    role.nameExtern = item.role.name_extern
    role.short = item.role.short
    role.visibleextern = item.role.visibleextern
    regionRole.role = role

    const contact = new Contact()
    contact.id = item.contact.id
    contact.name = item.contact.name
    contact.email = item.contact.email
    contact.tel = item.contact.tel
    contact.mobile = item.contact.mobile
    regionRole.contact = contact

    regionRole.customerPivot = roleMap.get(role.id) ?? {
      role_public_view: '',
      role_public_name: '',
      role_sorting: 0,
    }

    if (regionRole.customerPivot.role_public_name === '') {
      regionRole.customerPivot.role_public_name = role.name
    }

    if (regionRole.customerPivot.role_public_view === '') {
      regionRole.customerPivot.role_public_view = role.name
    }

    if (regionRole.customerPivot.role_sorting === 0) {
      regionRole.customerPivot.role_sorting = role.id
    }

    return regionRole
  }

  /**
   *
   * @param id region role id
   * @returns instance of RegionRole
   */
  static findById(id: string): RegionRole | null {
    for (let i = 0; i < RegionRoleService.regionRoles.length; i++) {
      if (RegionRoleService.regionRoles[i].id.toString() === id) {
        return RegionRoleService.regionRoles[i]
      }
    }

    return null
  }

  /**
   *
   * @param captcha captcha or null
   * @param customer custom id
   * @param embedded embedded flag
   * @param zip zip id
   * @returns list of RegionRole objects or captcha
   */
  static async getRegionRolesOrCaptcha(
    captcha: Captcha | null,
    customer: number,
    embedded: number,
    zip: String
  ): Promise<RegionRole[] | Captcha> {
    const token = captcha?.token
    const result = captcha?.result
    let captchaQuery = ''

    const requestHeaders: HeadersInit = new Headers()

    if (token && result) {
      captchaQuery = '&captcha=' + result
      requestHeaders.set('reCaptchaResponse', token)
    }

    const response = await fetch(
      process.env.REACT_APP_API_URL +
        '/api/regionRoles?r=1' +
        captchaQuery +
        '&customer=' +
        customer +
        '&embedded=' +
        embedded +
        '&zip=' +
        zip,
      {
        headers: requestHeaders,
      }
    )

    const customersResponse = await fetch(process.env.REACT_APP_API_URL + '/api/customers')
    const customersResponseJson = (await customersResponse.json()) as Customers
    const customerQuery = customersResponseJson.customers.find((c) => c.id == Number(process.env.REACT_APP_CUSTOMER_ID))

    const roleMap = new Map<number, CustomerPivot>()
    for (const role of customerQuery?.roles ?? []) {
      roleMap.set(role.id, role.pivot)
    }
    console.log(roleMap)

    if (response.status === 429) {
      const captcha = new Captcha()
      captcha.token = response.headers.get('Captcha-Token')
      captcha.question = response.headers.get('Captcha-Question')

      return captcha
    }

    const jsonResponse = await response.json()

    const regionRoles: RegionRole[] = []

    jsonResponse.regionRoles.forEach((item: any) => {
      regionRoles.push(this.fromObject(item, roleMap))
    })

    RegionRoleService.regionRoles = regionRoles

    return regionRoles
  }
}
