import React, { RefObject } from 'react'
import { Navigate } from 'react-router-dom'
import styled from 'styled-components'

import { useConfig } from '../contexts/config'
import Contact from '../models/Contact'
import Region from '../models/Region'
import Role from '../models/Role'
import Zip from '../models/Zip'
import { sendEvent } from '../services/ParentEvents'
import { RegionRoleService } from '../services/RegionRoleService'
import { ServiceCenterService } from '../services/ServiceCenterService'
import Address from './Address'
import Button from './Button'
import Captcha from './Captcha'
import { Heading } from './Heading'
import Input from './Input'
import Select from './Select'

type Props = {
  regionRole: string
  zip: string
  selectedZip?: Zip
  url: string
}

type State = {
  subjects: { label: string; value: any }[]
  privacy: boolean
  showPrivacyError: boolean
  showSubjectError: boolean
  subject: { label: string; value: any }
  showWaiting: boolean
  showError: boolean
  navigateTo?: string | null
  showCaptcha: boolean
  captchaToken: string | null
  captchaQuestion: string | null
  term: string
  open: boolean
  captchaResult: string | null
  showSuccess: boolean
  role: Role
  region: Region
  contact: Contact
  lastname?: string
  email?: string
  formErrors: FormError[]
}

const StyledLink = styled.a`
  color: ${(props) => props.theme.accent};
`

const StyledForm = styled.form`
  margin: 40px 0px;
`

const StyledFormControl = styled.div`
  margin-bottom: 20px;
`

const StyledActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 100%;
`

type FormError = {
  name: string
  message: string
}

const EMAIL_REGEX = /^[\w+-]+(?:\.[\w+-]+)*@[\da-z]+(?:[.-][\da-z]+)*\.[a-z]{2,}$/iu

class LeadForm extends React.Component<Props, State> {
  static subjects = [
    {
      label: 'Anfrage',
      value: 0,
    },
    {
      label: 'Beschwerde',
      value: 1,
    },
    {
      label: 'Sonstiges',
      value: 2,
    },
  ]

  state: any = {
    subjects: LeadForm.subjects,
    privacy: false,
    showPrivacyError: false,
    subject: null,
    showWaiting: false,
    showError: false,
    navigateTo: null,
    showSubjectError: false,
    showCaptcha: false,
    captchaToken: null,
    captchaQuestion: null,
    term: '',
    open: false,
    captchaResult: null,
    showSuccess: false,
    formErrors: [],
  }

  formRef: RefObject<HTMLFormElement>

  constructor(props: Props) {
    super(props)
    this.handleSubmitForm = this.handleSubmitForm.bind(this)
    this.handleOnSelectInputChange = this.handleOnSelectInputChange.bind(this)
    this.handleOnSelect = this.handleOnSelect.bind(this)
    this.handleOnSelectTriggerClicked = this.handleOnSelectTriggerClicked.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.formRef = React.createRef()
    this.handlePrivacyChange = this.handlePrivacyChange.bind(this)
    this.handleCaptchaSubmit = this.handleCaptchaSubmit.bind(this)
  }

  async componentDidMount() {
    sendEvent({ name: 'start' })

    const { regionRole } = this.props
    const regionRoleObj = RegionRoleService.findById(regionRole)

    regionRoleObj?.role
    this.setState({
      role: regionRoleObj?.role as Role,
      contact: regionRoleObj?.contact as Contact,
      region: regionRoleObj?.region as Region,
    })
  }

  handlePrivacyChange(e: any) {
    this.setState({
      privacy: !this.state.privacy,
    })
  }

  handleCaptchaSubmit(result: string) {
    this.setState({ captchaResult: result }, () => {
      this.sendData()
    })
  }

  handleSubmitForm(e: any) {
    e.preventDefault()
    return this.sendData()
  }

  validate() {
    const formErrors: FormError[] = []
    const eventErrors: string[] = []

    const { lastname, email, privacy, subject } = this.state
    if (!subject) {
      formErrors.push({ name: 'subject', message: 'Bitte Betreff angeben' })
      eventErrors.push('SELECT.subject:unselected')
    }
    if (!lastname) {
      formErrors.push({ name: 'lastname', message: 'Bitte eingeben' })
      eventErrors.push('INPUT.lastname:empty')
    }
    if (!email) {
      formErrors.push({ name: 'email', message: 'Bitte eingeben' })
      eventErrors.push('INPUT.email:empty')
    } else if (!EMAIL_REGEX.test(email)) {
      formErrors.push({ name: 'email', message: 'Bitte eine gültige E-Mail-Adresse eingeben' })
      eventErrors.push('INPUT.email:invalid')
    }
    if (!privacy) {
      formErrors.push({ name: 'privacy', message: 'Bitte nehmen Sie unsere Datenschutzerklärung zur Kenntnis.' })
      eventErrors.push('CHECKBOX.privacy:unchecked')
    }
    this.setState({ formErrors: formErrors })

    if (eventErrors.length > 0) {
      sendEvent({ name: 'error', data: eventErrors.join('|') })
    }

    return formErrors.length === 0
  }

  async sendData() {
    const form = this.formRef.current

    if (!form) {
      // something went totally wrong :D
      return
    }

    // validate
    if (!this.validate()) {
      return
    }
    sendEvent({ name: 'send' })

    const { subject } = this.state

    // serialize form
    const formData = new FormData(form)
    const data = new URLSearchParams()
    for (const pair of formData.entries()) {
      data.append('lead[' + pair[0] + ']', pair[1].toString())
    }
    data.append('lead[subject]', subject.label)
    data.append('lead[widget_url]', this.props.url)

    // build captcha query if result provided
    const { captchaToken, captchaResult } = this.state

    let captchaQuery = ''
    if (captchaToken && captchaResult) {
      captchaQuery = `?token=${captchaToken}&value=${captchaResult}`
    }

    // send lead to api
    this.setState({ showWaiting: true, showCaptcha: false })
    const response = await fetch(process.env.REACT_APP_API_URL + '/api/leads' + captchaQuery, {
      body: data,
      method: 'POST',
    })

    // check if captcha needed
    if (response.status === 499) {
      // show captcha
      const json = await response.json()
      this.setState({
        showCaptcha: true,
        captchaToken: json.captcha.token,
        captchaQuestion: json.captcha.label,
        captchaResult: null,
        showWaiting: false,
      })
      return false
    }

    if (response.status === 200 || response.status === 201) {
      // continue
      // TODO: show success banner
      this.setState({
        showWaiting: false,
        showSuccess: true,
      })

      sendEvent({ name: 'submit' })

      return false
    }

    this.setState({
      showWaiting: false,
      showError: true,
    })

    return false
  }

  handleCancel(e: any) {
    this.setState({ navigateTo: '/' })
  }

  handleOnSelectInputChange(e: any) {
    this.setState({ term: e.target.value })
    const subjects = LeadForm.subjects.filter((subject: { label: string; value: any }) =>
      subject.label.toLowerCase().includes(e.target.value.toLowerCase())
    )
    this.setState({ subjects: subjects })
  }

  handleOnSelect(subject: { label: string; value: any }) {
    this.setState({
      subject: subject,
      open: false,
    })
  }

  handleOnSelectTriggerClicked(e: any) {
    this.setState({ open: !this.state.open })
  }

  getErrorMessage(name: string): string | undefined {
    const errorMessage: FormError | undefined = this.state.formErrors.find(
      (formError: FormError) => formError.name === name
    )
    return errorMessage?.message
  }

  render() {
    const {
      open,
      subject,
      subjects,
      navigateTo,
      showPrivacyError,
      showSubjectError,
      showWaiting,
      showError,
      contact,
      role,
      region,
      showCaptcha,
      captchaQuestion,
      showSuccess,
    } = this.state

    const { regionRole, zip } = this.props

    if (navigateTo) {
      return <Navigate to={navigateTo} state={{ zip: this.props.selectedZip }} />
    }

    if (showError) {
      return (
        <div>
          <Heading kind="h2">Es ist ein Fehler aufgetreten!</Heading>
          <p>
            Fehler! Das Formular konnte wegen technischer Fehler nicht abgesendet werden. Hier finden Sie die
            Kontaktdaten:
          </p>
          <p>
            E-Mail: <StyledLink href={'mailto:' + contact.email}>{contact.email}</StyledLink>
            <br />
            Telefon: <StyledLink href={'tel:' + contact.tel}>{contact.tel}</StyledLink>
            <br />
          </p>
        </div>
      )
    }

    if (showSuccess) {
      return (
        <div>
          <Heading kind="h2">Vielen Dank für Ihre Nachricht.</Heading>
          <p>
            {contact.name}, {role.nameExtern} für {region.name} wurde benachricht.
          </p>
          <p>
            Sie erhalten zudem eine Eingangsbestätigung per E-Mail, in der Sie weitere Kontaktdaten Ihrer Beraterin oder
            Ihres Beraters vorfinden, um z.B. weitere Anhänge mitzusenden.
          </p>
          <Button outlined label="Neue Suche starten" onClick={this.handleCancel} />
        </div>
      )
    }

    return (
      <div>
        {showWaiting && <p>Senden...</p>}
        {showCaptcha && <Captcha question={captchaQuestion} onSubmit={this.handleCaptchaSubmit} />}
        <div style={{ display: showCaptcha || showWaiting ? 'none' : 'block' }}>
          <Heading kind="h2">Senden Sie eine Nachricht</Heading>
          <p>Und erhalten Sie mit der Empfangsbestätigung weitere Kontaktdaten wie Telefonnummer und E-Mailadresse.</p>
          {regionRole && <Address regionRole={regionRole} />}
          <StyledForm ref={this.formRef} onSubmit={this.handleSubmitForm}>
            <StyledFormControl>
              <Select
                entries={subjects}
                open={open}
                placeholder="Betreff *"
                selected={subject}
                errorMessage={this.getErrorMessage('subject')}
                onChange={this.handleOnSelectInputChange}
                onSelect={this.handleOnSelect}
                onTriggerClicked={this.handleOnSelectTriggerClicked}
              />
            </StyledFormControl>
            <StyledFormControl>
              <Input
                label="Vor- und Zuname *"
                errorMessage={this.getErrorMessage('lastname')}
                name="lastname"
                type="text"
                onChange={(e: any) => this.setState({ lastname: e.target.value })}
              />
            </StyledFormControl>
            <StyledFormControl>
              <Input
                label="E-Mail *"
                errorMessage={this.getErrorMessage('email')}
                name="email"
                type="email"
                onChange={(e: any) => this.setState({ email: e.target.value })}
              />
            </StyledFormControl>
            <StyledFormControl>
              <Input label="Telefon" name="tel" type="tel" onChange={() => {}} />
            </StyledFormControl>
            <StyledFormControl>
              <Input label="Firma" name="company" type="text" onChange={() => {}} />
            </StyledFormControl>
            <StyledFormControl>
              <Input label="Position" name="job" type="text" onChange={() => {}} />
            </StyledFormControl>
            <StyledFormControl>
              <Input label="Ihre Nachricht" type="textarea" name="message" onChange={() => {}} />
            </StyledFormControl>
            <StyledFormControl>
              <Input
                label="Ich habe die <a href='http://www.schindler.com/de/internet/de/system/disclaimer-navigation/datenschutzerklaerung.html'>Datenschutzerklärung</a> zur Kenntnis genommen."
                type="checkbox"
                name="privacy"
                errorMessage={this.getErrorMessage('privacy')}
                onChange={this.handlePrivacyChange}
              />
            </StyledFormControl>
            <input type="hidden" name="role" value={role ? role.id : ''} />
            <input type="hidden" name="zip" value={zip} />
            <input type="hidden" name="newsletter" value="0" />
            <input type="hidden" name="customer" value="1" />
            <input type="hidden" name="widget_identifier" value="Kontakt" />
            <StyledActionContainer>
              <Button label="Senden" type="submit" onClick={() => {}} />
              <Button outlined label="Neue Suche starten" onClick={this.handleCancel} />
            </StyledActionContainer>
            <div style={{ margin: '40px 0px', fontSize: '15px' }}>* sind Pflichtfelder</div>
          </StyledForm>
        </div>
      </div>
    )
  }
}

export default function WithUrl(props: Exclude<Props, 'URL'>) {
  const config = useConfig()

  return <LeadForm {...props} url={config.url} />
}
