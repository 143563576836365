import React, { ChangeEventHandler, ReactNode } from "react";
import styled from 'styled-components';

type Props = {
    children: ReactNode[]
};

type State = {

};

const StyledAccordion = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`;

export default class Accordion extends React.Component<Props, State> {

    state: State = {};

    render() {
        const {
            children
        } = this.props;

        return (
            <StyledAccordion>
                {children}
            </StyledAccordion>
        );
    }
}