import Zip from '../models/Zip'

export abstract class ZipService {
  /**
   *
   * @param term search term
   * @returns list of matched zip objects
   */
  static async autocomplete(term: string): Promise<Zip[]> {
    const response = await fetch(process.env.REACT_APP_API_URL + '/api/zips/autocomplete/' + term)
    const jsonResponse = await response.json()

    const zips: Zip[] = []

    jsonResponse.zips.forEach((item: any) => {
      const zip = new Zip()
      zip.id = item.id
      zip.city = item.city
      zips.push(zip)
    })

    return zips
  }
}
