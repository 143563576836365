import React, { ChangeEventHandler } from "react";
import styled from 'styled-components';

type Props = {
    onClick: React.MouseEventHandler,
    label: string,
    outlined?: boolean,
    type?: "submit" | "button" | undefined
};

type State = {

};

const StyledButton = styled.button<any>`
    background-color: ${props => props.theme.accent};
    color: ${props => props.theme.white};
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    border: none;
    padding: 8px 24px;
    cursor: pointer;
    transition: background-color 0.2s;
    border-radius: 3px;

    &:hover {
        background-color: ${props => props.theme.accentHover};
    }
`;

const StyledButtonOutlined = styled(StyledButton)`
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.accent};
    border: 1px solid ${props => props.theme.accent};

    &:hover {
        background-color: ${props => props.theme.accentHoverLight};
    }
`;



export default class Button extends React.Component<Props, State> {

    state: State = {};

    render() {
        const {
            label,
            onClick,
            outlined,
            type
        } = this.props;

        return outlined ? (
                <StyledButtonOutlined type={type ?? 'button'} onClick={onClick}>
                    {label}
                </StyledButtonOutlined>
            ) : (
                <StyledButton type={type ?? 'button'} onClick={onClick}>
                    {label}
                </StyledButton>
            );
    }
}