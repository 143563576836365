import React from 'react';
import styled from 'styled-components';

import RegionRole from '../models/RegionRole';
import ServiceCenter from '../models/ServiceCenter';
import { RegionRoleService } from '../services/RegionRoleService';
import { ServiceCenterService } from '../services/ServiceCenterService';

const StyledAddress = styled.div`
    color: ${props => props.theme.grey04};
    font-size: 18px;
    line-height: 26px;
    font-weight: 300;

    a {
        color: ${props => props.theme.accent};
    }
`;

type Props = {
    regionRole: string
};

type State = {
    regionRole: RegionRole | null,
    serviceCenter: ServiceCenter | null
};

export default class Address extends React.Component<Props, State> {

    state: State = {
        regionRole: null,
        serviceCenter: null
    };

    constructor(props: Props) {
        super(props);
    }

    async componentDidMount() {
        const { regionRole } = this.props;
        const regionRoleObj = RegionRoleService.findById(regionRole);
        let servicecenter = null;

        if (regionRoleObj !== null && regionRoleObj.region !== null) {
            servicecenter = await ServiceCenterService.findOneByRegion(regionRoleObj?.region);
        }

        this.setState({
            regionRole: regionRoleObj,
            serviceCenter: servicecenter
        });
    }

    render() {
        const {
            regionRole,
            serviceCenter
        } = this.state;

        return (
            <StyledAddress>
                {regionRole &&
                    <p>
                        <strong>{regionRole.contact?.name}, {regionRole?.role?.nameExtern}</strong><br />
                        {serviceCenter?.name}<br />
                        {serviceCenter?.street}<br />
                        {serviceCenter?.plz} {serviceCenter?.city}<br />
                        Servicenummer: <a href={"tel:" + serviceCenter?.tel}>{serviceCenter?.tel}</a>
                    </p>}
            </StyledAddress>
        );
    }
}