import { useLocation,useParams } from "react-router-dom";

import LeadForm from "../components/LeadForm";

const Form = () => {
    const {
        zip,
        regionRole
    } = useParams();
    const location = useLocation();
    const selectedZip = location.state?.selectedZip;
    return (
        <div>
            <LeadForm selectedZip={selectedZip} zip={zip ?? ''} regionRole={regionRole ?? ''} />
        </div>
    );
}

export default Form;