import { useLocation } from "react-router-dom";

import SearchForm from "../components/SearchForm"

const Search = () => {
    const location = useLocation();

    return <SearchForm location={location} />
}

export default Search;