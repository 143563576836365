import React, { ChangeEventHandler, ReactNode, Ref, RefObject } from "react";
import styled from 'styled-components';

import ArrowIcon from '../assets/icons/arrow.svg';
import { Heading } from "./Heading";

type Props = {
    label: string,
    children: ReactNode[]
};

type State = {
    open: boolean
};

const StyledAccordionItem = styled.li`
    margin: 0;
    padding: 0;
    list-style: none;
    border-bottom: 1px solid ${props => props.theme.grey01};
    padding-top: 30px;
`;

const StyledTrigger = styled.div<any>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    svg {
        transition: transform 0.5s ease;
        transform: rotate(${(props) => props.open ? '180deg' : '0deg'});
    }
`;

const StyledBody = styled.div`
    overflow: hidden;
    height: 0;
    transition: height 0.5s ease;

    .inner {
        padding: 0 0 30px;
    }
`;

const StatusIcon = styled.i<any>`
    transform: rotate(${props => props.open ? '180deg' : '0deg'});
    cursor: inherit;
    background-image: url("${props => props.icon}");
    width: 24px;
    height: 24px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    transition: all .3s ease-in-out;
    margin-right: 15px;
`;

export default class AccordionItem extends React.Component<Props, State> {

    state: State = {
        open: false
    };

    bodyRef: RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);
        this.handleOnTriggerClick = this.handleOnTriggerClick.bind(this);
        this.bodyRef = React.createRef();
    }

    handleOnTriggerClick(e: any) {
        e.preventDefault();

        const { open } = this.state;

        this.setState({ open: !this.state.open });

        const body = this.bodyRef.current;
        if (body && !open) {
            const scrollHeight = body.scrollHeight;
            body.style.height = scrollHeight?.toString() + 'px';
        } else if (body && open) {
            body.style.height = '0px';
        }
    }

    render() {
        const {
            label,
            children
        } = this.props;

        const {
            open
        } = this.state;

        return (
            <StyledAccordionItem>
                <StyledTrigger
                    onClick={this.handleOnTriggerClick}
                >
                    <Heading kind="h3">{label}</Heading>
                    <StatusIcon
                        open={open}
                        icon={ArrowIcon}
                    />
                </StyledTrigger>
                <StyledBody ref={this.bodyRef}>
                    <span className="inner">
                        {children}
                    </span>
                </StyledBody>
            </StyledAccordionItem>
        );
    }
}