import React, { ChangeEventHandler, RefObject } from "react";
import styled from 'styled-components';

import arrow from '../assets/icons/arrow.svg';
import Zip from "../models/Zip";

type Props = {
    onSubmit: Function,
    zipSelected: Zip | null,
    onChange: ChangeEventHandler<HTMLInputElement>,
    onTriggerClicked: Function,
    searchPlaceholder: string,
    loadingMessage: string,
    noMatchesMessage: string,
    searchMessage: string,
    placeholder: string,
    results: Zip[],
    showWaiting: boolean,
    onSelect: Function,
    open: boolean
};

type State = {
    term: string
};

const PowerSelectContainer = styled.div`
    max-width: 600px;
    margin-bottom: 20px;
    position: relative;
`;

const SelectTrigger = styled.div`
    border: 1px solid ${props => props.theme.grey03};
    border-radius: 3px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: ${(props) => props.theme.white};
`;

const Placeholder = styled.span<any>`
    color: ${props => props.theme.black};
    font-weight: ${props => props.selected ? 500 : 300};
    font-size: 16px;
    cursor: inherit;
`;

const Dropdown = styled.div<any>`
    display: ${props => props.display ? 'block' : 'none'};
    box-shadow: 0 0 16px rgba(0,0,0,.16);
    max-height: 250px;
    border-left: 1px solid ${(props) => props.theme.grey03};
    border-right: 1px solid ${(props) => props.theme.grey03};
    line-height: 1.75;
    border-radius: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: none;
    overflow: hidden;
    color: inherit;
    border-top: none;
    border-bottom: 1px solid ${(props) => props.theme.grey03};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    position: absolute;
    width: calc(100% - 2px);
    top: 50px;
    left: 0;
    background-color: ${props => props.theme.white};
`;

const StatusIcon = styled.i<any>`
    transform: rotate(${props => props.display ? '180deg' : '0deg'});
    cursor: inherit;
    background-image: url("${props => props.icon}");
    width: 24px;
    height: 24px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    transition: all .3s ease-in-out;
    margin-right: 15px;
`;

const SearchInputField = styled.input`
    border: 1px solid ${props => props.theme.grey02};
    padding: 0 5px;
    margin: 4px;
    width: calc(100% - 8px);
    font-weight: 300;
    font-size: 19.5px;
    line-height: 1.75;
    this.showWaitng
    &::placeholder {
        color: #676361;
    }
`;

const ResultList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0px;
    user-select: none;
    max-height: 12.25em;
    overflow-y: auto;
`;

const ResultEntry = styled.li`
    display: flex;
    align-items: flex-start;
    font-size: 16px;
    padding: 8px 16px;
    border: 1px solid;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
    border-color: ${props => props.theme.white} ${props => props.theme.white} ${props => props.theme.grey01};
    cursor: pointer;
    transition: color .3s ease-in-out;  
    line-height: 1.75;

    &:hover {
        background-color: ${props => props.theme.grey01};
        color: ${props => props.theme.black};
        border-color: ${props => props.theme.grey03};
    }
`;

export default class PowerSelect extends React.Component<Props, State> {
    state: State = {
        term: '',
    };

    inputRef: RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props); SearchInputField
        this.handleOnTriggerClick = this.handleOnTriggerClick.bind(this);
        this.handleOnInputChange = this.handleOnInputChange.bind(this);
        this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        this.inputRef.current?.addEventListener("keydown", this.handleOnKeyDown);
    }

    handleOnTriggerClick = (e: any) => {
        this.props.onTriggerClicked();
        setTimeout(() => {
            this.inputRef.current?.focus();
        }, 250);
    }

    handleOnInputChange(e: any) {
        this.setState({
            term: e.target.value,
        });
        this.props.onChange(e);
    }

    handleOnKeyDown(e: any) {
        const {
            results,
            onSelect
        } = this.props;
        if (e.key === 'Enter' && results.length > 0) {
            e.preventDefault();
            onSelect(results[0]);
        }
    }

    renderDefaultEntry = () => {

        const {
            searchMessage,
            showWaiting,
            loadingMessage,
            results,
            noMatchesMessage,
            zipSelected
        } = this.props;

        const {
            term
        } = this.state;

        if (showWaiting) {
            return <ResultEntry>
                {loadingMessage}
            </ResultEntry>
        }

        const inputLength = this.inputRef.current?.value.length ?? 0;
        if (results.length === 0 && zipSelected === null && inputLength > 3) {
            return <ResultEntry>
                {noMatchesMessage}
            </ResultEntry>
        }

        return <ResultEntry>
            {searchMessage}
        </ResultEntry>
    }

    renderResults = () => {
        const resultEntries: any[] = [];
        const {
            results,
            onSelect
        } = this.props;

        results.forEach(item => {
            resultEntries.push(
                <ResultEntry key={item.id} onClick={(e: any) => onSelect(item)}>
                    {item.id} - {item.city}
                </ResultEntry>
            );
        });

        return resultEntries;
    }

    render() {
        const {
            searchPlaceholder,
            results,
            zipSelected,
            open
        } = this.props;

        return (
            <PowerSelectContainer>
                <SelectTrigger onClick={this.handleOnTriggerClick}>
                    <Placeholder selected={zipSelected !== null}>
                        {zipSelected === null ? searchPlaceholder : zipSelected.id}
                    </Placeholder>
                    <StatusIcon
                        className="ember-power-select-status-icon"
                        display={open}
                        icon={arrow}
                    />
                </SelectTrigger>
                <Dropdown display={open}>
                    <SearchInputField ref={this.inputRef} type={'search'} placeholder={searchPlaceholder} onChange={this.handleOnInputChange} />
                    <ResultList>
                        {results.length > 0 ? (
                            this.renderResults()
                        ) : (
                            this.renderDefaultEntry()
                        )}
                    </ResultList>
                </Dropdown>
            </PowerSelectContainer>
        );
    }
}