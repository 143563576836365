import Contact from './Contact'
import { CustomerPivot } from './Customers'
import Region from './Region'
import Role from './Role'

export default class RegionRole {
  id: number = 0
  createdAt: string = ''
  updatedAt: string = ''
  region: Region | null = null
  contact: Contact | null = null
  role: Role | null = null
  rolePublicName: string = ''
  roleLevel: number = 0

  customerPivot: CustomerPivot = {
    role_public_view: '',
    role_public_name: '',
    role_sorting: 0,
  }
}
