import RegionRole from './RegionRole'
import ServiceCenter from './ServiceCenter'

export default class Region {
  id: number = 0
  name: string = ''
  short: string = ''
  regionRoles: RegionRole[] = []
  serviceCenters: ServiceCenter | null = null
}
