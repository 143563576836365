import Contact from './Contact'
import Region from './Region'

export default class ServiceCenter {
  id: number = 0
  name: string = ''
  tel: string = ''
  street: string = ''
  city: string = ''
  plz: string = ''
  gmapCode: string = ''
  region: Region | null = null
  contacts: Contact[] = []
}
