import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { ConfigProvider } from './contexts/config'
import FormComponent from './pages/Form'
import Search from './pages/Search'
import themeData from './theme'

const App = () => {
  return (
    <ThemeProvider theme={themeData}>
      <BrowserRouter>
        <ConfigProvider>
          <Routes>
            <Route path="/" element={<Search />} />
            <Route path="/form/:zip/:regionRole" element={<FormComponent />} />
          </Routes>
        </ConfigProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
