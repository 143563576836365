import styled from 'styled-components';

type Props = {
    kind: "h2" | "h3",
    children: string
};

const StyledHeading2 = styled.h2<any>`
    font-size: 32px;
    line-height: 40px;
    color: ${props => props.theme.accent};
    font-weight: 300;
`;

const StyledHeading3 = styled.h3<any>`
    font-size: 24px;
    line-height: 36px;
    color: ${props => props.theme.black};
    font-weight: 300;
    letter-spacing: .2px;
`;

export const Heading = ({ kind, children }: Props) => {
    return kind === 'h2' ? <StyledHeading2>{children}</StyledHeading2> : <StyledHeading3>{children}</StyledHeading3>;
}