import Region from '../models/Region'
import ServiceCenter from '../models/ServiceCenter'

export abstract class ServiceCenterService {
  static async findOneByRegion(region: Region | number): Promise<ServiceCenter | null> {
    const response = await fetch(process.env.REACT_APP_API_URL + '/api/servicecenters?r=1')
    const jsonResponse = await response.json()

    if (region instanceof Region) {
      region = region.id
    }

    for (let i = 0; i < jsonResponse.servicecenters.length; i++) {
      const item = jsonResponse.servicecenters[i]
      if (region !== item.region) {
        continue
      }
      const servicecenter = new ServiceCenter()
      servicecenter.id = item.id
      servicecenter.name = item.name
      servicecenter.street = item.street
      servicecenter.tel = item.tel
      servicecenter.plz = item.plz
      servicecenter.city = item.city
      servicecenter.gmapCode = item.gmap_code

      return servicecenter
    }

    return null
  }
}
