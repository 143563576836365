import React, { useMemo } from "react";
import { Location, Navigate } from "react-router-dom";
import styled from "styled-components";

import Accordion from "../components/Accordion";
import AccordionItem from "../components/AccordionItem";
import Button from "../components/Button";
import CaptchaComponent from "../components/Captcha";
import { Heading } from "../components/Heading";
import PowerSelect from "../components/PowerSelect";
import Captcha from "../models/Captcha";
import Region from "../models/Region";
import RegionRole from "../models/RegionRole";
import ServiceCenter from "../models/ServiceCenter";
import Zip from "../models/Zip";
import { RegionRoleService } from "../services/RegionRoleService";
import { ServiceCenterService } from "../services/ServiceCenterService";
import { ZipService } from "../services/ZipService";

const StyledAddress = styled.p`
  color: ${(props) => props.theme.grey04};
  font-size: 18px;
  line-height: 26px;
  font-weight: 300;
`;

const StyledMap = styled.iframe<any>`
  min-height: 200px;
  width: 100%;
  border: none;
`;

const StyledHeadline = styled.p`
  font-size: 18px;
  line-height: 26px;
  font-weight: 300;
  color: ${(props) => props.theme.grey04};
  margin: 40px 0px;
`;

type Props = {
  location: Location;
};

type State = {
  showWaiting: boolean;
  results: Zip[];
  selectedZip: Zip | null;
  term: string;
  servicecenter: ServiceCenter | null;
  regionRoles: RegionRole[];
  captcha: Captcha | null;
  open: boolean;
  navigateTo: string | null;
};

export default class SearchForm extends React.Component<Props, State> {
  state: State = {
    showWaiting: false,
    results: [],
    selectedZip: null,
    term: "",
    servicecenter: null,
    regionRoles: [],
    captcha: null,
    open: false,
    navigateTo: null,
  };

  constructor(props: Props) {
    super(props);
    this.handleOnInputChange = this.handleOnInputChange.bind(this);
    this.handleOnButtonClick = this.handleOnButtonClick.bind(this);
    this.handleOnSelect = this.handleOnSelect.bind(this);
    this.handleOnCaptchaSubmit = this.handleOnCaptchaSubmit.bind(this);
    this.handleOnTriggerClick = this.handleOnTriggerClick.bind(this);
    this.navigateToForm = this.navigateToForm.bind(this);
  }

  componentDidMount(): void {
    const selectedZip = this.props.location.state?.zip ?? null;
    console.log(selectedZip);
    this.setState({ selectedZip }, this.loadRegionData);
  }

  handleOnInputChange(e: any) {
    // validate search term
    if (!e.target.value || e.target.value.length < 3) {
      this.setState({ results: [] });
      return;
    }

    const term = e.target.value;
    this.setState({ term: term });

    this.search(term);
  }

  handleOnButtonClick(e: any) {
    e.preventDefault();
    const { term, selectedZip } = this.state;

    if (selectedZip === null) {
      this.search(term);
    }
  }

  handleOnSelect(zip: Zip) {
    this.setState(
      {
        selectedZip: zip,
        results: [],
      },
      () => {
        this.loadRegionData();
      }
    );
  }

  handleOnCaptchaSubmit(result: String) {
    const { captcha } = this.state;
    if (captcha !== null) {
      captcha.result = result;
    }

    this.setState({ captcha: captcha }, this.loadRegionData);
  }

  async loadRegionData() {
    const { selectedZip, captcha } = this.state;

    const result = await RegionRoleService.getRegionRolesOrCaptcha(captcha, 1, 1, selectedZip?.id as string);

    if (result instanceof Captcha) {
      this.setState({
        regionRoles: [],
        servicecenter: null,
        captcha: result,
        open: false,
      });
    } else {
      let servicecenter: ServiceCenter | null = null;

      if (result.length > 0) {
        servicecenter = await ServiceCenterService.findOneByRegion(result[0].region as Region);
      }

      this.setState({
        regionRoles: result,
        servicecenter: servicecenter,
        captcha: null,
        open: false,
      });
    }
  }

  async search(term: string) {
    this.setState({ showWaiting: true });

    // query api
    const zips = await ZipService.autocomplete(term);

    if (term.length === 5 && zips.length === 1) {
      // auto select this zip
      this.setState(
        {
          showWaiting: false,
          selectedZip: zips[0],
          results: [],
        },
        () => {
          this.loadRegionData();
        }
      );
    } else {
      // set results
      this.setState({
        showWaiting: false,
        selectedZip: null,
        results: zips,
      });
    }
  }

  handleOnTriggerClick() {
    this.setState({
      open: !this.state.open,
    });
  }

  navigateToForm(e: any, regionRole: RegionRole) {
    const { selectedZip } = this.state;

    const route = "/form/" + selectedZip?.id + "/" + regionRole.id;

    this.setState({
      navigateTo: route,
    });
  }

  renderRegionRoles() {
    const rows: any[] = [];
    const { regionRoles } = this.state;

    const sortedRegionRoles = regionRoles.sort((a, b) => a.customerPivot.role_sorting - b.customerPivot.role_sorting);

    for (let i = 0; i < sortedRegionRoles.length; i++) {
      rows.push(
        <AccordionItem key={i} label={regionRoles[i].customerPivot.role_public_name || ""}>
          <p>{regionRoles[i].contact?.name}</p>
          <Button label="Kontaktieren" onClick={(e: any) => this.navigateToForm(e, regionRoles[i])} />
        </AccordionItem>
      );
    }

    return rows;
  }

  render() {
    const { showWaiting, results, selectedZip, regionRoles, servicecenter, captcha, open, navigateTo } = this.state;

    if (navigateTo !== null) {
      return <Navigate to={navigateTo} state={{ selectedZip }} />;
    }

    return (
      <div>
        <div>
          <PowerSelect
            open={open}
            zipSelected={selectedZip}
            searchPlaceholder="PLZ oder Ort"
            loadingMessage="Orte werden geladen"
            noMatchesMessage="Es wurden keine Orte gefunden"
            searchMessage="Tippen Sie eine PLZ oder Ort ein"
            placeholder="PLZ oder Ort"
            results={results}
            showWaiting={showWaiting}
            onTriggerClicked={this.handleOnTriggerClick}
            onSubmit={() => {}}
            onChange={this.handleOnInputChange}
            onSelect={this.handleOnSelect}
          />
          <Button label={"Beratung finden"} onClick={this.handleOnButtonClick} />
        </div>

        {captcha && (
          <div style={{ margin: "20px 0px" }}>
            <CaptchaComponent question={captcha.question ?? ""} onSubmit={this.handleOnCaptchaSubmit} />
          </div>
        )}

        {servicecenter && (
          <div>
            <Heading kind="h2">Ihre Hauptgeschäftsstelle in der Region</Heading>
            <StyledAddress>
              {servicecenter.name} <br />
              {servicecenter.street} <br />
              {servicecenter.plz} <br />
              {servicecenter.city} <br />
              Servicenummer: {servicecenter.tel}
            </StyledAddress>
            <StyledMap src={servicecenter.gmapCode} frameborder="0"></StyledMap>
            <StyledHeadline>
              <strong>Zur weiteren Verfeinerung wählen Sie bitte Ihre Kontaktperson</strong>
            </StyledHeadline>
            <Accordion>{this.renderRegionRoles()}</Accordion>
          </div>
        )}
      </div>
    );
  }
}
