
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'

export interface Config {
  url: string
}

const defaultConfig: Config = {
  url: document.location.origin,
}

const ConfigContext = createContext<Config>(defaultConfig)

export const ConfigProvider = ({ children }: { children: ReactNode }) => {
  const [config, setConfig] = useState<Config>(defaultConfig)

  const parseUrlParams = () => {
    const params = new URLSearchParams(document.location.search)
    const url = params.get('url') || defaultConfig.url
    setConfig((config) => ({ ...config, url }))
  }

  useEffect(() => {
    parseUrlParams()
  }, [])

  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
}

export const useConfig = () => {
  const context = useContext(ConfigContext)
  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider')
  }

  return context
}
