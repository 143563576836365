import RegionRole from './RegionRole'
import ServiceCenter from './ServiceCenter'

export default class Contact {
  id: number = 0
  name: string = ''
  email: string = ''
  tel: string = ''
  mobile: string = ''
  regionRoles: RegionRole[] = []
  serviceCenter: ServiceCenter | null = null
}
